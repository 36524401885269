import { red } from '@mui/material/colors';
import { esES } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

const colors = {
  black: '#000000',
  link: '#3371DD',
  estimateStatus: {
    cancelled: 'rgb(196, 196, 196)',
    created: 'rgb(255, 207, 135)',
    loading: 'rgba(226, 225, 223, 0.74)',
    pending: 'rgb(255, 241, 165)',
    processing: 'rgba(32, 77, 239, 0.41)',
    success: 'rgba(34, 231, 65, 0.44)',
    unset: 'rgb(132, 212, 226)',
  },
  orderStatus: {
    in_progress: 'rgba(30, 144, 255, 0.5)',
    returned: 'rgb(255, 99, 71, 0.5)',
  },
};

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#299790',
      },
      secondary: {
        main: '#333333',
      },
      error: {
        main: red.A400,
      },
      approved_without_signature: {
        main: colors.estimateStatus.success,
        contrastText: colors.black,
      },
      cancel: {
        main: colors.estimateStatus.cancelled,
        contrastText: colors.black,
      },
      cancelled: {
        main: colors.estimateStatus.cancelled,
        contrastText: colors.black,
      },
      completed: {
        main: colors.estimateStatus.success,
        contrastText: colors.black,
      },
      created: {
        main: colors.estimateStatus.created,
        contrastText: colors.black,
      },
      email_opened: {
        main: colors.estimateStatus.pending,
        contrastText: colors.black,
      },
      initiated: {
        main: colors.estimateStatus.loading,
        contrastText: colors.black,
      },
      draft: {
        main: colors.estimateStatus.loading,
        contrastText: colors.black,
      },
      sent: {
        main: colors.estimateStatus.pending,
        contrastText: colors.black,
      },
      signed: {
        main: colors.estimateStatus.success,
        contrastText: colors.black,
      },
      unset: {
        main: colors.estimateStatus.unset,
        contrastText: colors.black,
      },
      in_progress: {
        main: colors.orderStatus.in_progress,
        contrastText: colors.black,
      },
      returned: {
        main: colors.orderStatus.returned,
        contrastText: colors.black,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            margin: '0',
            padding: '0',
            height: '100%',
          },
          body: {
            margin: '0',
            padding: '0',
            height: '100%',
          },
          '&#root': {
            height: '100%',
            minHeight: '100%',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              borderRadius: '4px',
              boxShadow:
                '0px 3px 1px -2px #c1c1c1, 0px 2px 2px #D9D9D9, 0px 1px 5px #D9D9D9',
              backgroundColor: 'rgba(0, 0, 0, 0.12);',
              color: '#8A8A8A',
            },
          },
          sizeSmall: {
            height: '36px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: '24px',
          },
        },
      },
    },
  },
  esES
);

declare module '@mui/material/styles' {
  interface Palette {
    approved_without_signature: Palette['primary'];
    cancel: Palette['primary'];
    completed: Palette['primary'];
    created: Palette['primary'];
    cancelled: Palette['primary'];
    email_opened: Palette['primary'];
    draft: Palette['primary'];
    initiated: Palette['primary'];
    sent: Palette['primary'];
    signed: Palette['primary'];
    unset: Palette['primary'];
    in_progress: Palette['primary'];
    returned: Palette['primary'];
  }

  interface PaletteOptions {
    approved_without_signature?: PaletteOptions['primary'];
    cancel?: PaletteOptions['primary'];
    completed?: PaletteOptions['primary'];
    created?: PaletteOptions['primary'];
    cancelled?: PaletteOptions['primary'];
    email_opened?: PaletteOptions['primary'];
    draft?: PaletteOptions['primary'];
    initiated?: PaletteOptions['primary'];
    sent?: PaletteOptions['primary'];
    signed?: PaletteOptions['primary'];
    unset?: PaletteOptions['primary'];
    in_progress?: PaletteOptions['primary'];
    returned?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    approved_without_signature: true;
    cancel: true;
    cancelled: true;
    completed: true;
    created: true;
    email_opened: true;
    draft: true;
    initiated: true;
    sent: true;
    signed: true;
    unset: true;
    in_progress: true;
    returned: true;
  }
}

export default theme;
export { colors };
