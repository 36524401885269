'use client';

import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import dynamic from 'next/dynamic';

// TODO - this is a temporary solution. Import using the path to the library.
// eslint-disable-next-line @nx/enforce-module-boundaries
import theme from '../../../../libs/revenue-spa/src/assets/theme';

const RevenueSpa = dynamic(() => import('revenue-spa'), {
  loading: () => (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </ThemeProvider>
  ),
  ssr: false,
});

export default async function Index() {
  return <RevenueSpa />;
}
